const LOCALES = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Español',
    code: 'es',
  },
];

export default LOCALES;
